import React from "react";
import 'bootstrap/dist/css/bootstrap.css';

import { Link } from 'gatsby';
import { graphql } from 'gatsby'
import Img from 'gatsby-image';

import Header from '../components/header';
import Meta from '../components/meta';

import {FaFacebookF} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';

export const query = graphql`
  query {
    slabImage: file(relativePath: { regex: "/^backlit-nephrite-jade-bar.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    wallImage: file(relativePath: { regex: "/^wall.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    furnitureImage: file(relativePath: { regex: "/^furniture.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    lightingImage: file(relativePath: { regex: "/^light.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    gemstoneImage: file(relativePath: { regex: "/^nephrite-gemstone-jewelry-slabs.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
  }
`

class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      background: 'gemstoneImage'
    };

    this.handleImageHover = this.handleImageHover.bind(this);
  };

  handleImageHover = (imageName) => {
    this.setState({
      background: imageName
    });
  };

  render() {
    return(
      <div className="black-background pt-5 px-5 pb-0 container-fluid">
        <Meta />
      
        <div id="background-image" className="black-background">
          {this.state.background === 'slabImage' ? <Img fluid={this.props.data.slabImage.childImageSharp.fluid} alt="" /> : null }
          {this.state.background === 'wallImage' ? <Img fluid={this.props.data.wallImage.childImageSharp.fluid} alt="" /> : null }
          {this.state.background === 'furnitureImage' ? <Img fluid={this.props.data.furnitureImage.childImageSharp.fluid} alt="" /> : null }
          {this.state.background === 'lightingImage' ? <Img fluid={this.props.data.lightingImage.childImageSharp.fluid} alt="" /> : null }
          {this.state.background === 'gemstoneImage' ? <Img fluid={this.props.data.gemstoneImage.childImageSharp.fluid} alt="" /> : null }
        </div>

        <Header darkFont={false}/>

        {/* MAIN CONTENT */}
        <div className="row justify-content-center pt-5 mt-5">
          <div className="col-12 text-center">
            <ul className="px-0 my-5">
              <li className="py-2" onMouseEnter={() => {this.handleImageHover('slabImage')}}><Link to="/products/slabs-and-surfaces"><h4 className="default-links border-on-hover d-inline text-white serif font-weight-lighter">Slabs and Surfaces</h4></Link></li>
              <li className="py-2" onMouseEnter={() => {this.handleImageHover('wallImage')}}><Link to="/products/feature-walls"><h4 className="default-links border-on-hover d-inline text-white serif font-weight-lighter">Feature Walls</h4></Link></li>
              <li className="py-2" onClick={() => {this.handleImageHover('furnitureImage')}} onMouseEnter={() => {this.handleImageHover('furnitureImage')}}><h4 className="default-links border-on-hover d-inline text-white serif font-weight-lighter cursor-pointer">Furniture</h4></li>
              <li className="py-2" onClick={() => {this.handleImageHover('lightingImage')}} onMouseEnter={() => {this.handleImageHover('lightingImage')}}><h4 className="default-links border-on-hover d-inline text-white serif font-weight-lighter cursor-pointer">Lighting</h4></li>
              <li className="py-2" onMouseEnter={() => {this.handleImageHover('gemstoneImage')}}><Link to="/products/gemstone"><h4 className="default-links border-on-hover d-inline text-white serif font-weight-lighter">Gemstone</h4></Link></li>
            </ul>
          </div>
          <div className="col-12 text-center">
            <h3 className="green-accent sans-serif">
            {this.state.background === 'slabImage' ? <span>Super. Natural. Stone.</span> : null }
            {this.state.background === 'wallImage' ? <span>Gemstone Wall Features</span> : null }
            {this.state.background === 'furnitureImage' || this.state.background === 'lightingImage' ? <span>Coming Soon</span> : null }
            {this.state.background === 'gemstoneImage' ? <span>Raw&nbsp;Gemstone &amp; Jewelry&nbsp;Slabs</span> : null }
            </h3>
            <h6 className="text-light sans-serif pt-2 pb-5 light-text-shadow">
              {this.state.background === 'slabImage' ? <span>Gemstone slabs proudly sourced and<br className="d-none d-sm-block" /> manufactured in British Columbia</span> : null }
              {this.state.background === 'wallImage' ? <span>Easy-to-mount wall hangings of reclaimed nephrite jade.</span> : null }
              {this.state.background === 'furnitureImage' ? <span>Custom furniture and unique, live-edge <br/> tables made from reclaimed BC nephrite.</span> : null }
              {this.state.background === 'lightingImage' ? <span>Exclusive line of luxury light products <br/> including lamps, sconces, and pendants.</span> : null }
              {this.state.background === 'gemstoneImage' ? <span>Ethically sourced BC nephrite jade.</span> : null }
            </h6>
          </div>
        </div>

        {/* CUSTOM FOOTER */}
        <div className="row justify-content-center">
          <div className="col-12 text-center mb-5">
            <div className="row">
              <div className="col-4 text-right"><Link className='default-links sans-serif text-white text-nowrap light-text-shadow' to="/about-us">About Us</Link></div>
              <div className="col-4 text-center"><Link className='default-links sans-serif text-white light-text-shadow' to="/contact">Contact</Link></div>
              <div className="col-4 text-left"><Link className='default-links sans-serif text-white light-text-shadow' to="/faqs">FAQs</Link></div>
            </div>
          </div>
          <div className="col-12 text-center">
            <a href="https://www.facebook.com/vancouverjadee/" title="Vancouver Jade Facebook" target="_blank" rel="noopener noreferrer" className="text-white d-inline-block mr-4 light-text-shadow"><h3><FaFacebookF className="social-media-link border rounded-circle p-1"/></h3></a>
            <a href="https://www.instagram.com/vancouverjade/?hl=en" title="Vancouver Jade Instagram" target="_blank" rel="noopener noreferrer" className="text-white d-inline-block ml-4 light-text-shadow"><h3><FaInstagram className="social-media-link border rounded-circle p-1"/></h3></a>
          </div>
        </div>

      </div>
    )
  }
}

export default Index;
